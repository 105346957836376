'use strict';

/* Polyfills */
if (typeof NodeList.prototype.forEach === "undefined") {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof HTMLCollection.prototype.forEach === "undefined") {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

/* App Class */
class App {

	constructor(){
		this.header = document.querySelector('.header');
		this.topMenu = document.querySelector('.topmenu');
		this.bottomMenu = document.querySelector('.bottommenu');
		this.logos = document.querySelectorAll('.logo');
		this.phone = document.querySelector('.header .phone');
		this.mobilePhone = document.querySelector('.menu-phone');
	}

	init(){
		this.menuRunner();
		this.headerRunner();
		this.logoAnchor();
	}

	menuRunner(){
		let self = this;
		let toggle = self.topMenu.querySelector('button');
		let nav = self.topMenu.querySelector('nav');
		if (toggle) toggle.addEventListener('click', e => {
			e.preventDefault();
			self.menuToggle();
		});
		if (nav) {
			self.menuFindActive(nav);
			self.bottomMenu.innerHTML = nav.innerHTML;
		};
		if (self.phone && self.mobilePhone) self.mobilePhone.innerHTML = self.phone.innerHTML;
	}

	menuToggle(){
		this.topMenu.classList.toggle('active');
	}

	menuFindActive(nav){
		let currentPage = location.pathname.replace('/','');
		nav.childNodes.forEach((child) => {
			let anchor = child.tagName === 'A' ? child.getAttribute('href').replace('/','') : false;
			if (currentPage === anchor) child.classList.add('active');
		})
	}

	headerRunner(){
		window.addEventListener('scroll', e => {
			let pos = this.header.getBoundingClientRect().height;
			let scroll = window.scrollY;
			let fixed = this.header.classList.contains('fixed');
			if (pos <= scroll && !fixed) this.header.classList.add('fixed');
			if (pos > scroll && fixed) this.header.classList.remove('fixed');
		})
	}

	logoAnchor(){
		if (location.pathname !== '/') {
			this.logos.forEach((logo) => {
				logo.innerHTML = '<a href="/">' + logo.innerHTML + '</a>';
			})
		}
	}


}

const app = new App();

window.addEventListener('DOMContentLoaded', () => app.init());